import logo from './logo.png';
import findYourFlow from './fyf-90s.jpg';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {CalendarMonth, Facebook, Instagram, LocalAtm, YouTube} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent, CardMedia,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    TextField
} from "@mui/material";
import React, {useState, useEffect} from "react";
import * as ReactGA from "react-ga";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const dateToHumanDateStr = dateObj => dayNames[dateObj.getDay()] + " " + monNames[dateObj.getMonth()] + " " + dateObj.getDate() + " " + dateObj.getHours() % 12 + "pm";

export default function App() {

  let [lessons, setLessons] = useState([]);
  let [alert, setAlert] = useState(null)

    var maxAge = new Date();
    maxAge.setDate(maxAge.getDate() - 14)

  useEffect(() => {
     const fetchCalendar = async() => {
       const url = "https://nzrxe9e4a4.execute-api.us-east-1.amazonaws.com/calendar";
       const cal = await fetch(url)
           .then(res => res.json())
           .then(lessons => lessons.filter((lesson) => new Date(lesson.start).getTime() > maxAge.getTime()))
           .then(lessons => setLessons(lessons))
     }

     fetchCalendar()
  }, [])


    ReactGA.initialize('G-F9YPQWKTFP');
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });

    const subscribe = async () => {
        const url = "https://neon2step.us17.list-manage.com/subscribe/post-json?u=903830d4195eb4152596b273e&amp;id=15bcec96b8&amp;f_id=007b7ce0f0&EMAIL=caguru%40gmail.com&c=__jp0"
        const params =  new URLSearchParams({
            EMAIL: document.getElementById('sub_email').value,
            FNAME: document.getElementById('sub_fname').value,
            LNAME: document.getElementById('sub_lname').value

        });

        await fetch(url, {
            method: "POST",
            mode: "no-cors",
            body: encodeURI(params)
        })
        .then(res => {
            // console.log(res.status)
          if (res.status == 200) {
              alert("hw")
              setAlert({status: 200, msg: res.text()})
          }
        })


        return;

    }

  return (
      <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
          <div className="App">
              <Snackbar
                  open={alert}
                  autoHideDuration={3000}
                  message=""
              />
              <div style={{margin: "auto", textAlign: "center", marginTop:"40px"}}>
                  <Box sx={{ borderRadius: '5px' }}>
                     <img src={logo} className="App-logo" alt="logo" />
                  </Box>
              </div>
              <Grid container className={"content"}>
                  <Grid item sm={12}>
                      <Card>
                          <CardContent><img src={findYourFlow} width={550}
                                            style={{
                                                borderRadius: "10px"
                                            }}/>
                                <h2>Find Your Flow</h2>
                              <h4>90's 2 Step Edition</h4>

                                   <p>If there is one thing we miss about 2-stepping, it's traveling around the dance floor while singing along to some 90's country music. Let's fix that!</p>

<p>This workshop is a special opportunity to really find the groove in progressive 2 step without being too formal in an absolutely gorgeous venue: Shady Springs Party Barn (close to airport in SE Austin).</p>

<p>We will have stuff for leads and follows, swingy and progressive dancers! We hope you join us!</p>
                              <p>Thur, Feb 20th<br />Doors 7:30, Start 8</p>
                              <p>Shady Springs Party Barn<br/>9401 Sherman Rd, Austin, TX 78742 </p>
                              <p>$20 advance/$25 day of</p>
                              {/*    <p>See registration for more info. Limited Tickets -> Register now!</p>*/}
                              <p><em>See promo video on Instagram <a href={"https://www.instagram.com/p/DF8iToCJKaB/"}>here</a></em></p>
                              <h4>ON SALE NOW</h4>
                              <Button
                                  href={"https://www.eventbrite.com/e/90s-2-step-workshop-tickets-1233873521919?aff=oddtdtcreator"}
                                  variant={"contained"}>Register</Button>

                      </CardContent>
                      </Card>
                      <h4>Upcoming Group Lessons</h4>
                      <List>
                          {lessons.map(lesson =>
                              <ListItem>
                                  <ListItemText
                                      primary={dateToHumanDateStr(new Date(lesson.start))}
                                      secondary={lesson.summary + " @ " + lesson.location.split(',')[0]}/>
                              </ListItem>
                          )}
                      </List>
                  </Grid>
                  {/*<Grid item md={6}>*/}
                  {/*    <h4>Mailing List</h4>*/}
                  {/*    Enter your email below to subscribe for future announcments including lessons and special events.*/}
                  {/*    <br/>*/}
                  {/*    <form>*/}
                  {/*        <div>*/}
                  {/*          <TextField size="small" label={"Email"} id="sub_email" value="neon2step@gmail.com"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*          <TextField size="small" label={"First Name"} id="sub_fname" value="Tim" />*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*          <TextField size="small" label={"Last Name"} id="sub_lname" value="Martin" />*/}
                  {/*        </div>*/}
                  {/*        <IconButton onClick={(e) => subscribe(e)}>Subscribe</IconButton>*/}
                  {/*    </form>*/}
                  {/*    <MailchimpSubscribe*/}
                  {/*        url={"https://neon2step.us17.list-manage.com/subscribe/post?u=903830d4195eb4152596b273e&amp;id=15bcec96b8&amp;f_id=007b7ce0f0"}/>*/}
                  {/*</Grid>*/}




                  {/*<Grid item md={6}>*/}
                  {/*    <form*/}
                  {/*        action="https://neon2step.us17.list-manage.com/subscribe/post?u=903830d4195eb4152596b273e&amp;id=15bcec96b8&amp;f_id=007b7ce0f0"*/}
                  {/*        method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"*/}
                  {/*        className="validate" target="_blank">*/}
                  {/*          <div id="mc_embed_signup_scroll"><h2>Subscribe for updates</h2>*/}
                  {/*              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>*/}
                  {/*              <div className="mc-field-group"><label htmlFor="mce-EMAIL">Email Address <span*/}
                  {/*                  className="asterisk">*</span></label><input type="email" name="EMAIL" className="required email"*/}
                  {/*                                                              id="mce-EMAIL" required="" value=""/></div><div*/}
                  {/*                  className="mc-field-group"><label htmlFor="mce-FNAME">First Name </label><input type="text"*/}
                  {/*                                                                                                  name="FNAME"*/}
                  {/*                                                                                                  className=" text"*/}
                  {/*                                                                                                  id="mce-FNAME"*/}
                  {/*                                                                                                  value=""/></div><div*/}
                  {/*                  className="mc-field-group"><label htmlFor="mce-LNAME">Last Name </label><input type="text"*/}
                  {/*                                                                                                 name="LNAME"*/}
                  {/*                                                                                                 className=" text"*/}
                  {/*                                                                                                 id="mce-LNAME"*/}
                  {/*                                                                                                 value=""/></div>*/}
                  {/*          <div id="mce-responses" className="clear foot">*/}
                  {/*              <div className="response" id="mce-error-response" style={{display: "none"}}></div>*/}
                  {/*              <div className="response" id="mce-success-response" style={{display: "none"}}></div>*/}
                  {/*          </div>*/}
                  {/*      <div aria-hidden="true" style={{position: "absolute", left: "-5000px"}}>*/}
                  {/*          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  {/*          <input type="text" name="b_903830d4195eb4152596b273e_15bcec96b8" tabIndex="-1" value=""/>*/}
                  {/*      </div>*/}
                  {/*          <div className="optionalParent">*/}
                  {/*              <div className="clear foot">*/}
                  {/*                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button"*/}
                  {/*                         value="Subscribe"/>*/}
                  {/*                  <p style={{margin: "0px auto"}}><a href="http://eepurl.com/iXFWZQ"*/}
                  {/*                                                  title="Mailchimp - email marketing made easy and fun"><span*/}
                  {/*                      style={{display: "inline-block", backgroundColor: "transparent", borderRadius: "4px"}}><img*/}
                  {/*                      className="refferal_badge"*/}
                  {/*                      src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"*/}
                  {/*                      alt="Intuit Mailchimp"*/}
                  {/*                      style={{width: "220px", height: "40px", display: "flex", padding: "2px 0px", justifyContent: "center", alignItems: "center"}}/></span></a></p>*/}
                  {/*              </div>*/}
                  {/*          </div>*/}
                  {/*      </div>*/}
                  {/*  </form>*/}
                  {/*</Grid>*/}



                  <Grid item sm={12}>
                      <h4>Where to Dance</h4>
                      Check out the <Link href={"https://austin2step.com"}>Austin 2 Step Calendar</Link> for a full list of all the best places to dance.
                  </Grid>
                  <Grid item sm={12}>
                      <h4>About</h4>
                      <div>
                          <p>Howdy, my name is Tim and I love dancing. While I have always loved dancing, the bug bit me hard around 2012 and I haven't stopped dancing since.</p>
                      </div>
                  </Grid>
              </Grid>

        </div>
          <div className="FooterStripe">
                      <IconButton href={"https://www.facebook.com/neon2step"}
                                  aria-label="Neon 2 Step Facebook Page"><Facebook/></IconButton>
                      <IconButton href={"https://www.instagram.com/neon2step"}><Instagram/></IconButton>
                      <IconButton href={"https://www.youtube.com/@blameitallonmyboots"}><YouTube/></IconButton>
                      <IconButton href={"https://www.venmo.com/timplode"}><LocalAtm/></IconButton>
                    <Button size="small" id="whereToTwoStep" href={"https://austin2step.com"} variant={"contained"}><span style={{fontFamily: "arial", fontWeight:900, fontSize:"70%"}}>WHERE TO 2-STEP </span> <CalendarMonth/></Button>

             </div>
    </ThemeProvider>
  );
}
